import { AxiosInstance } from "axios";
import { useCallback } from "react";
import type {
    GetOrgSettingResultModel,
    GetUserProfileResultModel,
    GetOrganizationStatusModel,
    GetStartedStatus,
} from "services/generated";
import useServerQuery, { ILoad, IOutput } from "utils/server-query";

export const orgsDataName = "myOrganisations";
export const orgsQuery = (axios: AxiosInstance) => axios.get("/api/app/orgs/v1");

export const profileQuery = (axios: AxiosInstance) => axios.get("/api/app/users/v1/me/profile");
const defaultSettings: ILoad<GetUserProfileResultModel> = {
    name: "clientProfile",
    query: profileQuery,
    shouldReset: false,
};
export const useProfile = (settings?: Partial<ILoad<GetUserProfileResultModel>>) =>
    useServerQuery<GetUserProfileResultModel>({ ...defaultSettings, ...settings });

export const orgDetails = "organizationSettings";
export const useOrgDetailsQuery = ({
    org,
    ...rest
}: Partial<ILoad<GetOrgSettingResultModel>> & { org?: string | null }) => {
    const query = useCallback(
        (axios: AxiosInstance) =>
            org ? axios.get(`/api/app/orgs/v1/${org}/settings`) : Promise.reject(new Error("Org cannot be empty")),
        [org]
    );

    return useServerQuery<GetOrgSettingResultModel>({ ...rest, query, name: orgDetails });
};

const bannerQuery = (axios: AxiosInstance) => axios.get(`/api/cl/orgs/v1/current/status`);
export const useBannerQuery = (options: Partial<ILoad<GetOrganizationStatusModel>> | undefined = {}) =>
    useServerQuery<GetOrganizationStatusModel>({ ...options, query: bannerQuery, name: "banner" });

export const getStartedName = "getStarted";
const getStartedQuery = (axios: AxiosInstance) => axios.get(`/api/cl/orgs/v1/current/get-started-status`);
export const useGetStartedQuery = (
    options: Partial<ILoad<GetOrganizationStatusModel>> | undefined = {}
): IOutput<GetStartedStatus> & { notFinished?: number; finishedPercent?: number } => {
    const result = useServerQuery<GetStartedStatus>({
        disableLoad: true,
        shouldReset: false,
        ...options,
        query: getStartedQuery,
        name: getStartedName,
    });
    if (!result.data) {
        return result;
    }
    const actionableItems = Object.entries(result.data).filter(
        ([key]) =>
            key !== "initSynchDone" &&
            key !== "isFirstRun" &&
            key !== "isBillingConfigured" &&
            key !== "isStripeWasSkipped"
    );
    const notFinished = actionableItems.map(([key, value]) => (value ? 0 : 1)).reduce<number>((p, n) => p + n, 0);

    if (notFinished === 0) {
        return { ...result, notFinished: 0, finishedPercent: 0 };
    }

    const finishedPercent = 100 - notFinished * (100 / actionableItems.length);

    return { ...result, notFinished, finishedPercent };
};
