import React, { FC, useRef, useEffect, useMemo, PropsWithChildren } from "react";
import axios, { AxiosInstance } from "axios";
import { useSelector } from "react-redux";
import { useAuth0, WithAuthenticationRequiredOptions } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";

import { IAppState } from "state";
import { TransparentLoader } from "components/loader";
import useServerQuery from "utils/server-query";
import { orgsDataName, orgsQuery } from "@client/utils/query";
import { ClientState, GetCurrentUserPermissionsResultModel, UserOrgsModel } from "services/generated";
import withAuthenticationRequired from "./withAuthenticationRequired";
import { PermissionName } from "./permissions";

const query = (axiosInstance: AxiosInstance) => axiosInstance.get("/api/app/users/v1/me/permissions");
const name = "permissions";

export const permissionsSelector = (state: IAppState): PermissionName[] => state.loadedData[name]!.data.permissions;
export const usePermissions = () => useSelector(permissionsSelector);

export const useAxiosAuthHeader = (org?: string, attachIfAuthenticatedOnly?: boolean) => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const interceptor = useRef<number>(0);
    useEffect(() => {
        interceptor.current = axios.interceptors.request.use(async (config) => {
            if (org) {
                config.headers!.Org = org;
            }

            if (!attachIfAuthenticatedOnly || isAuthenticated) {
                const token = await getAccessTokenSilently();
                if (token && !config.headers?.Authorization) {
                    config.headers!.Authorization = `Bearer ${token}`;
                }
            }

            return config;
        });
        return () => {
            axios.interceptors.request.eject(interceptor.current);
        };
    }, [getAccessTokenSilently, org, attachIfAuthenticatedOnly, isAuthenticated]);
};

function withAuth<P extends object>(
    Component: React.ReactNode,
    options: WithAuthenticationRequiredOptions = {}
): FC<P> {
    function WithPermissions() {
        const { org } = useParams<{ org: string }>();
        useAxiosAuthHeader(org);
        const { data, loading, error } = useServerQuery<GetCurrentUserPermissionsResultModel>({
            name,
            query,
        });
        const { loading: orgsLoading, error: orgError } = useServerQuery<UserOrgsModel>({
            name: orgsDataName,
            query: orgsQuery,
        });
        const navigate = useNavigate();
        useEffect(() => {
            if ([404, 403].includes((error?.response?.status ?? 0) || (orgError?.response?.status ?? 0))) {
                navigate("/not-found");
                return;
            }
            if (data?.clientState && data.clientState !== ClientState.NotSuspended) {
                let description = "This account has been suspended";
                if (data.clientState === ClientState.SuspendedBillsOutstanding) {
                    description = "This account has been suspended due to non-payment";
                    if (data.permissions.length > 0) {
                        return;
                    }
                }

                navigate({
                    pathname: "/verify",
                    hash: `error=suspended&error_description=${description}`,
                });
            }
        }, [data, error, navigate, orgError]);
        const onRedirecting = options.onRedirecting || (() => <div>Loading...</div>);
        const Redirecting = onRedirecting();
        return data && !loading && !orgsLoading ? Component : Redirecting;
    }

    return withAuthenticationRequired(WithPermissions, options);
}

const onRedirecting = () => <TransparentLoader loading />;

const SecureRoute = ({ children }: PropsWithChildren) => {
    const ComponentWithAuth = useMemo(() => withAuth(children, { onRedirecting }), [children]);

    return <ComponentWithAuth />;
};

export default SecureRoute;
