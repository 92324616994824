import React, { FC } from "react";
import { CircularProgress, Box, Paper, Theme, SxProps } from "@mui/material";
import { useSelector } from "react-redux";

import { getLoading } from "reducers/loading";

export const LoaderComponent: FC<SxProps<Theme>> = (props) => (
    <CircularProgress sx={{ margin: "auto", display: "block", ...props }} />
);

const boxStyles = {
    display: "flex",
    minHeight: "150px",
    minWidth: "150px",
};

export const BoxedLoader = () => (
    <Paper style={boxStyles} elevation={0}>
        <LoaderComponent />
    </Paper>
);

const getStyles = (sx: SxProps<Theme> | undefined) => ({
    root: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        display: "flex",
        zIndex: 10,
        alignItems: "center",
        ...sx,
    } as const,
    hoverImage: {
        backgroundColor: "gainsboro",
        opacity: 0.5,
    } as const,
});

interface TransparentLoaderProps {
    loading: boolean;
    sx?: SxProps<Theme>;
}

export const TransparentLoader: FC<TransparentLoaderProps> = ({ loading, sx }) => {
    const styles = getStyles(sx);
    return loading ? (
        <>
            <Box sx={{ ...styles.root, ...styles.hoverImage }} />
            <Box sx={styles.root}>
                <LoaderComponent />
            </Box>
        </>
    ) : null;
};

const Loader: FC<{ sx?: SxProps<Theme> }> = ({ sx }) => {
    const loading = useSelector(getLoading);

    return <TransparentLoader loading={loading} sx={sx} />;
};

export default Loader;
