import React from "react";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { router } from "services/router";
import { authSettings } from "./authSettings";

const Auth0ProviderWithHistory = ({ children }: any) => {
    const onRedirectCallback = (appState?: AppState) => {
        router.navigate("/verify", { state: appState, replace: true });
    };

    return (
        <Auth0Provider
            domain={authSettings.jwtAuthority}
            clientId={authSettings.clientId}
            authorizationParams={{
                redirect_uri: `${window.location.origin}/verify`,
                audience: authSettings.jwtAudience,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
