import React, { PropsWithChildren } from "react";
import { Navigate, RouteProps, useLocation } from "react-router-dom";
import { PermissionName, usePermissions } from "@client/auth";

export type AuthorizedRouteWithRRD = IAuthorizedRouteProps & RouteProps;
export interface IAuthorizedRouteProps {
    permission?: PermissionName | PermissionName[];
    mode?: "any" | "every";
}

const AuthorizedRoute = ({ permission = [], mode = "every", children }: PropsWithChildren<IAuthorizedRouteProps>) => {
    const permissions = usePermissions();
    const location = useLocation();
    const requiredPermissions = Array.isArray(permission) ? permission : [permission];
    const authorized =
        mode === "every"
            ? requiredPermissions.every((rp) => permissions.includes(rp))
            : requiredPermissions.some((rp) => permissions.includes(rp));

    return authorized ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default AuthorizedRoute;
