import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "state";

const loadingSlice = createSlice({
    name: "loading",
    initialState: false,
    reducers: {
        setLoading: (_, action: PayloadAction<boolean>) => action.payload,
    },
});

export const { setLoading } = loadingSlice.actions;
export const getLoading = (state: IAppState) => state.loading;
export default loadingSlice.reducer;
