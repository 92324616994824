import React, { ComponentType, useEffect, FC } from "react";
import { useAuth0, WithAuthenticationRequiredOptions } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";

// eslint-disable-next-line react/jsx-no-useless-fragment
const defaultOnRedirecting = (): JSX.Element => <></>;

const withAuthenticationRequired = <P extends object>(
    Component: ComponentType<P>,
    options: WithAuthenticationRequiredOptions = {}
): FC<P> =>
    function WithAuthenticationRequired(props: P): JSX.Element {
        const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
        const { pathname, search } = useLocation();
        const navigate = useNavigate();
        const { onRedirecting = defaultOnRedirecting } = options;

        useEffect(() => {
            if (isLoading || isAuthenticated) {
                return;
            }

            getAccessTokenSilently().catch(() => navigate("/login", { state: { returnTo: pathname, search } }));
        }, [getAccessTokenSilently, isLoading, pathname, search, isAuthenticated, navigate]);

        return isAuthenticated ? <Component {...props} /> : onRedirecting();
    };

export default withAuthenticationRequired;
