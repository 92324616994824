import { applyMiddleware, ReducersMapObject, Reducer, Middleware, Action } from "redux";
import createSagaMiddleware, { SagaMiddleware, Saga } from "redux-saga";
import createReduxPromiseListener from "redux-promise-listener";

import StoreRegister from "services/store-register";
import { configureStore, Store, Tuple } from "@reduxjs/toolkit";
import createReducer from "./reducers";
import { IAppState } from "./state";

const sagaMiddleware = createSagaMiddleware();
export const promiseListener = createReduxPromiseListener();
export type AppStore = Store<IAppState> & {
    sagaMiddleware?: SagaMiddleware<object>;
    asyncReducers?: ReducersMapObject;
    sagas?: string[];
};

interface IInitReducers {
    [key: string]: {
        reducer?: Reducer;
        init?: any;
    };
}

export default (reducers: IInitReducers, sagas: Saga[]): AppStore => {
    const initState: IAppState = {
        loading: false,
        error: null,
        ...Object.entries(reducers).reduce((prev: any, [name, value]) => {
            // eslint-disable-next-line no-param-reassign
            prev[name] = value.init;
            return prev;
        }, {}),
    };
    const initReducers = Object.entries(reducers)
        .filter(([, value]) => !!value.reducer)
        .reduce((prev: any, [name, value]) => {
            // eslint-disable-next-line no-param-reassign
            prev[name] = value.reducer;
            return prev;
        }, {});

    const store: AppStore = configureStore<IAppState>({
        reducer: createReducer(initReducers),
        middleware: () => new Tuple(sagaMiddleware),
        preloadedState: initState,
        devTools: process.env.NODE_ENV !== "production" && typeof window !== "undefined",
        enhancers: (getDefaultEnhancers) =>
            getDefaultEnhancers().concat(
                new Tuple(
                    applyMiddleware(sagaMiddleware, promiseListener.middleware as Middleware<unknown, Action, any>)
                )
            ),
    });

    store.sagaMiddleware = sagaMiddleware;
    sagas.forEach((saga) => sagaMiddleware.run(saga));
    store.asyncReducers = initReducers;
    store.sagas = [];

    StoreRegister.setStore(store);
    return store;
};
