import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useQuery<Params = any>() {
    const { hash, search: urlSearch } = useLocation();
    const search = hash.length ? hash.substring(1) : urlSearch;
    return useMemo<Params>(() => {
        const params = new URLSearchParams(search);
        const obj: any = {};
        params.forEach((value, name) => {
            obj[name] = value;
        });
        return obj;
    }, [search]);
}
