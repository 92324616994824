export enum PermissionName {
    OrgCompleteOrganisationSetup = "Org.CompleteOrganisationSetup",
    OrgReadSettings = "Org.ReadOrgSettings",
    OrgReadUserTeams = "Org.ReadUserTeams",
    OrgUpdateUserTeams = "Org.UpdateUserTeams",
    OrgUpdateUserEnabledStatus = "Org.UpdateUserEnabledStatus",
    DebtClientReadInvoices = "Debt.Client.ReadInvoices",
    DebtClientReadCustomers = "Debt.Client.ReadCustomers",
    DebtClientWriteCustomerSettings = "Debt.Client.WriteCustomerSettings",
    OrgReadMessages = "Org.ReadMessages",
    OrgClientReadAffiliateSchemeState = "Org.Client.ReadAffiliateSchemeState",
    OrgItOpsCreateClientOrg = "Org.ItOps.CreateClientOrg",
    OrgItOpsSetSourceSystemIdForClient = "Org.ItOps.SetSourceSystemIdForClient",
    OrgItOpsSetPaymentSystemIdForClient = "Org.ItOps.SetPaymentSystemIdForClient",
    OrgItOpsCallCreateOrUpdateEventOnClients = "Org.ItOps.CallCreateOrUpdateEventOnClients",
    OrgItOpsWriteSubscriptions = "Org.ItOps.WriteSubscriptions",
    OrgClientReadDunningConfiguration = "Org.Client.ReadDunningConfiguration",
    OrgClientWriteDunningConfiguration = "Org.Client.WriteDunningConfiguration",
    OrgItOpsReadThemeSettings = "Org.ItOps.ReadThemeSettings",
    OrgItOpsWriteThemeSettings = "Org.ItOps.WriteThemeSettings",
    OrgItOpsReadAffiliateSchemeState = "Org.ItOps.ReadAffiliateSchemeState",
    ItOpsWriteAffiliate = "ItOps.WriteAffiliate",
    ItOpsReadClientSettings = "ItOps.ReadClientSettings",
    ItOpsWriteClientSettings = "ItOps.WriteClientSettings",
    OrgClientReadThemeSettings = "Org.Client.ReadBrandingSettings",
    OrgClientWriteThemeSettings = "Org.Client.WriteBrandingSettings",
    OrgClientReadSettings = "Org.Client.ReadOrgSettings",
    OrgClientWriteSettings = "Org.Client.WriteOrgSettings",
    OrgClientWriteMinimumAmountOverdueLimitSettings = "Org.Client.WriteMinimumAmountOverdueLimitOrgSettings",
    OrgClientCreatePaymentAccount = "Payment.Client.CreatePaymentAccount",
    OrgClientCreatePaymentAccountV2 = "Payment.Client.CreatePaymentAccountV2",
    OrgClientCompletePaymentAccountSetup = "Payment.Client.CompletePaymentAccountSetup",
    OrgClientReadBankTransferSettings = "Payment.Client.ReadBankTransferSettings",
    OrgClientWriteBankTransferSettings = "Payment.Client.WriteBankTransferSettings",
    OrgClientReadBankDetails = "Payment.Client.ReadBankDetails",
    OrgClientWriteBankDetails = "Payment.Client.WriteBankDetails",
    OrgClientReadPaymentSettings = "Payment.Client.ReadPaymentSettings",
    OrgClientWritePaymentSettings = "Payment.Client.WritePaymentSettings",
    OrgClientCompleteBankTransferSetup = "Payment.Client.CompleteBankTransferSetup",
    OrgClientReadAccountingSystem = "AccountingSystem.Client.ReadAccountingSystem",
    OrgClientWriteAccountingSystem = "AccountingSystem.Client.WriteAccountingSystem",
    OrgClientReadEscalations = "Org.Client.ReadEscalations",
    OrgClientReadPlans = "Org.Client.ReadPlans",
    PaymentClientReadPayments = "Payment.Client.ReadPayments",
    PaymentClientMakeRefund = "Payment.Client.MakeRefund",
    ActionClientReadActions = "Action.Client.ReadActions",
    BillingClientReadBillingDetails = "Payment.Client.ReadBillingDetails",
    BillingClientWritePaymentMethodSettings = "Payment.Client.WritePaymentMethodSettings",
    BillingClientCreateTestMonthlyPayment = "Payment.Client.CreateTestMonthlyPayment",
    BillingClientReadBills = "Billing.Client.ReadBills",
    OrgItOpsCreateClientTestPayout = "Org.ItOps.CreateClientTestPayout",
    OrgClientReadGetStartedState = "Org.Client.ReadGetStartedState",
    OrgItOpsEnableDisableClient = "Org.ItOps.EnableDisableClient",
    OrgItOpsRaiseMessage = "ItOps.RaiseMessage",
    OrgItOpsPerformAccountingSystemSync = "ItOps.PerformAccountingSystemSync",
    CreditManagerSendPaymentLink = "CreditManagement.Account.SendCustomerPaymentLink",
    CreditManagerReviewClientApplication = "CreditManagement.Account.ReviewClientApplication",
    CreditManagerLoadInvoiceDocument = "CreditManagement.Account.LoadInvoiceDocument",
    EscalationSaveCommunicationSettings = "Escalation.SaveCommunicationSettings",
    EscalationReadCommunicationSettings = "Escalation.ReadCommunicationSettings",
    EscalationReadClientStatus = "Escalation.ReadClientStatus",
}

export const signupPermissions = [
    PermissionName.OrgClientCreatePaymentAccount,
    PermissionName.OrgClientCompletePaymentAccountSetup,
    PermissionName.OrgClientReadBankTransferSettings,
    PermissionName.OrgClientWriteBankTransferSettings,
    PermissionName.OrgClientCompleteBankTransferSetup,
    PermissionName.OrgCompleteOrganisationSetup,
];
