import { createTheme, lighten } from "@mui/material/styles";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import type {} from "@mui/lab/themeAugmentation";
import React, { forwardRef } from "react";

const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>(
    (props, ref) => {
        const { href, ...other } = props;
        // Map href (Material UI) -> to (react-router)
        return <RouterLink ref={ref} to={href} {...other} />;
    }
);

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        inputCaption: true;
    }
}

declare module "@mui/material/styles" {
    interface Palette {
        white?: Palette["primary"];
    }
    interface PaletteOptions {
        white?: PaletteOptions["primary"];
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        white?: true;
    }

    interface ButtonPropsVariantOverrides {
        soft: true;
    }
}

export default () => {
    let theme = createTheme({
        palette: {
            primary: {
                main: "#0067f4",
            },
            secondary: {
                main: "#1b2145",
            },
            text: {
                primary: "#1b2145",
                secondary: "#8d90a2",
            },
            background: {
                default: "#f3f5f7",
            },
            white: {
                main: "#fff",
            },
        },
        typography: {
            fontFamily: "Poppins",
            fontSize: 12,
            button: {
                fontSize: "0.857rem",
            },
            h1: {
                fontSize: 30,
                fontWeight: "bold",
                lineHeight: 2.5,
            },
            h2: {
                fontSize: "1.125rem",
                fontWeight: "bold",
            },
            h3: {
                fontSize: "1rem",
                fontWeight: "bold",
            },
            h4: {
                fontSize: "0.875rem",
                fontWeight: 600,
            },
            h6: {
                fontSize: "0.9375rem",
                fontWeight: 500,
            },
            caption: {
                fontSize: "0.75rem",
                fontWeight: 500,
            },
            subtitle2: {
                fontSize: "0.75rem",
                fontWeight: "normal",
            },
        },
    });

    theme = createTheme(theme, {
        typography: {
            ...theme.typography,
            caption: {
                ...theme.typography.caption,
                color: theme.palette.text.secondary,
            },
            h1: {
                ...theme.typography.h1,
                marginBottom: theme.spacing(4),
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    button: {
                        fontFamily: "Poppins",
                    },
                },
            },
            MuiAlert: { styleOverrides: { root: { alignItems: "center" } } },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: theme.shape.borderRadius,
                        textTransform: "none",
                        fontWeight: "normal",
                        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    },
                },
                variants: [
                    {
                        props: { variant: "soft" },
                        style: {
                            border: "none",
                            fontWeight: 500,
                        },
                    },
                    {
                        props: { variant: "soft", color: "error" },
                        style: {
                            backgroundColor: lighten(theme.palette.error.light, 0.85),
                            color: theme.palette.error.main,
                            "&:hover": {
                                backgroundColor: lighten(theme.palette.error.light, 0.9),
                            },
                        },
                    },
                    {
                        props: { variant: "soft", color: "info" },
                        style: {
                            backgroundColor: lighten(theme.palette.info.light, 0.85),
                            color: theme.palette.info.main,
                            "&:hover": {
                                backgroundColor: lighten(theme.palette.info.light, 0.9),
                            },
                        },
                    },
                    {
                        props: { variant: "soft", color: "primary" },
                        style: {
                            backgroundColor: lighten(theme.palette.primary.light, 0.85),
                            color: theme.palette.primary.main,
                            "&:hover": {
                                backgroundColor: lighten(theme.palette.primary.light, 0.9),
                            },
                        },
                    },
                ],
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: "hover",
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    inputAdornedEnd: {
                        paddingRight: "39px",
                        marginRight: "-39px",
                    },
                    root: {
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                            {
                                display: "none",
                                WebkitAppearance: "none",
                            },
                        "& input[type=number]": {
                            MozAppearance: "textfield",
                        },
                    },
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    color: "success",
                },
            },
            MuiTypography: {
                variants: [
                    {
                        props: { variant: "inputCaption" },
                        style: { fontWeight: 600, fontSize: "0.625rem", marginLeft: "15px", marginBottom: "2px" },
                    },
                ],
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            background: "transparent",
                            color: theme.palette.primary.main,
                        },
                    },
                },
            },
            MuiChip: {
                defaultProps: {
                    size: "small",
                },
                styleOverrides: {
                    root: {
                        fontSize: "0.6rem",
                        height: "1.125rem",
                        textTransform: "uppercase",
                        borderRadius: (theme.shape.borderRadius as number) * 1.25,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        color: theme.palette.primary.main,
                        backgroundColor: lighten(theme.palette.primary.light, 0.85),
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        borderRadius: (theme.shape.borderRadius as number) * 1.25,
                    },
                    list: {
                        padding: theme.spacing(1),
                    },
                    root: {
                        "& .MuiMenuItem-root:not(:last-of-type)": {
                            marginBottom: theme.spacing(0.5),
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        minHeight: theme.spacing(4.5),
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1),
                        borderRadius: (theme.shape.borderRadius as number) * 0.75,
                        "& svg": {
                            marginRight: theme.spacing(2),
                        },
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        width: 42,
                        height: 24,
                        marginLeft: 8,
                        marginRight: 8,
                        padding: 0,
                        "& .MuiSwitch-switchBase": {
                            padding: 0,
                            margin: 2,
                            transitionDuration: "300ms",

                            "& .MuiSwitch-thumb": {
                                boxSizing: "border-box",
                                width: 20,
                                height: 20,
                            },

                            "&.Mui-disabled + .MuiSwitch-track": {
                                opacity: 0.7,
                                color: theme.palette.grey[100],
                            },
                            "&.Mui-checked": {
                                transform: "translateX(18px)",
                                color: "#fff",
                                "& + .MuiSwitch-track": {
                                    backgroundColor: theme.palette.primary.main,
                                    opacity: 1,
                                    border: 0,
                                },
                                "& .MuiSwitch-thumb": {
                                    color: theme.palette.primary.contrastText,
                                },
                                "&.Mui-disabled + .MuiSwitch-track": {
                                    opacity: 0.5,
                                },
                            },
                        },
                    },
                    track: {
                        borderRadius: 24 / 2,
                        backgroundColor: theme.palette.grey[500],
                        opacity: 1,
                        transition: theme.transitions.create(["background-color"], {
                            duration: 500,
                        }),
                    },
                },
            },
        },
    });

    theme.shadows[1] = "0 10px 20px 0 rgba(193, 200, 216, 0.3)";
    return theme;
};
