import React from "react";
import { useQuery } from "utils/router";
import { useQuery as useReactQuery } from "@tanstack/react-query";
import axios from "axios";
import { BcmDocumentLinkTokenResult } from "services/generated";

const BcmInvoiceDownload = () => {
    const { org, customer, invoice } = useQuery<{
        org?: string;
        customer?: string;
        invoice?: string;
    }>();
    const getInvoicePdf = () =>
        axios.get<BcmDocumentLinkTokenResult>(
            `/api/cm/debtors/v1/org/${org}/customer/${customer}/invoice/${invoice}/document`
        );

    const { data: documentLinkModel } = useReactQuery({
        queryKey: ["invoice-pdf"],
        queryFn: getInvoicePdf,
        select: (axiosData) => axiosData.data,
    });

    if (!documentLinkModel) {
        return null;
    }
    if (documentLinkModel) {
        return (
            <iframe
                style={{ width: "100dvw", height: "100dvh" }}
                title="Invoice PDF"
                src={`/api/documents/v1/${documentLinkModel.token}/${documentLinkModel.name}`}
            />
        );
    }

    return <div />;
};

export default BcmInvoiceDownload;
