import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getCanShowGetStarted, setCanShowGetStarted } from "utils/localStorage";

export const useCanShowGetStarted = () => {
    const { state } = useLocation();
    const [canShowGetStartedLS] = useState(() => getCanShowGetStarted());
    useEffect(() => {
        setCanShowGetStarted(null);
    }, []);

    return state?.canShowGetStarted || canShowGetStartedLS;
};

export const useCustomNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const goBackOrDefault = (defaultUrl: string) => (location.state.from ? navigate(-1) : navigate(defaultUrl));
    const pushWithCurrent = (url: string) => navigate(url, { state: { from: location.pathname + location.search } });
    return { goBackOrDefault, pushWithCurrent };
};
