import { useCallback, useEffect } from "react";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, put, call, select } from "redux-saga/effects";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";

import { IAppState } from "state";

export interface IState<T = any> {
    error?: AxiosError;
    loading: boolean;
    data?: T;
    loaded?: boolean;
}

export interface ILoad<T = any> {
    query: (axios: AxiosInstance) => Promise<AxiosResponse<T>>;
    name: string;
    shouldReset?: boolean;
    disableLoad?: boolean;
    force?: boolean;
}

interface ILoadDone<T = any> {
    name: string;
    error?: any;
    data?: T;
}

const loadData = createAction<ILoad>("load_data");

const loadDataSlice = createSlice({
    name: "load_data",
    initialState: {} as Record<string, IState | null>,
    reducers: {
        enableLoading: (state, { payload }: PayloadAction<string>) => {
            if (state[payload]) {
                state[payload]!.loading = true;
            }
        },
        loadDataDone: (state, { payload: { name, ...rest } }: PayloadAction<ILoadDone>) => {
            state[name] = { loading: false, loaded: true, ...rest };
        },
        reset: (state, { payload: name }: PayloadAction<string>) => {
            state[name] = null;
        },
    },
});

export const { loadDataDone, reset, enableLoading } = loadDataSlice.actions;
export const reducer = loadDataSlice.reducer;
export const getState = (name: string) => (appState: IAppState) => appState.loadedData[name];

export const getStateManager = <T>(name: string) => ({
    getState: (state: IAppState): T | null => getState(name)(state)?.data,
    setState: (data: T) => loadDataDone({ name, data }),
});

function* LoadData({ payload: { name, query, force } }: PayloadAction<ILoad>) {
    try {
        const state: IState = yield select(getState(name));
        if ((!state?.loaded && !state?.loading) || force) {
            yield put(enableLoading(name));
            const { data }: AxiosResponse = yield call(() => query(axios));
            yield put(loadDataDone({ name, data }));
        }
    } catch (error: any) {
        yield put(loadDataDone({ name, error }));
    }
}

export function* saga() {
    yield takeEvery(loadData.type, LoadData);
}

declare module "state" {
    export interface IAppState {
        loadedData: Record<string, IState | null>;
    }
}

export interface IOutput<T> {
    reload: () => void;
    updateData: (data: T) => void;
    error?: AxiosError<any, any> | undefined;
    loading: boolean;
    data?: T | undefined;
    loaded?: boolean | undefined;
}

export default function useServerQuery<T>({ name, query, shouldReset = true, disableLoad }: ILoad<T>): IOutput<T> {
    const dispatch = useDispatch();
    const state: IState<T> | null = useSelector(getState(name));

    useEffect(() => {
        if (!disableLoad) {
            dispatch(loadData({ name, query }));
        }

        return () => {
            if (shouldReset) {
                dispatch(reset(name));
            }
        };
    }, [dispatch, name, query, disableLoad, shouldReset]);

    const reload = useCallback(() => {
        dispatch(loadData({ name, query, force: true }));
    }, [name, query, dispatch]);

    const updateData = useCallback(
        (data: T) => {
            dispatch(loadDataDone({ name, data }));
        },
        [dispatch, name]
    );

    return state ? { ...state, reload, updateData } : { loading: !disableLoad, reload, updateData };
}
