import { Saga } from "redux-saga";
import { combineReducers, Reducer } from "@reduxjs/toolkit";
import createReducer from "reducers";
import { AppStore } from "store";
import { IAppState } from "state";

export default class StoreRegister {
    private static store: AppStore;

    public static setStore(newStore: AppStore) {
        this.store = newStore;
    }

    public static injectAsyncReducer(name: string, asyncReducer: Reducer<any, any>): void {
        this.store.asyncReducers![name] = asyncReducer;
        this.store.replaceReducer(combineReducers(createReducer(this.store.asyncReducers!) as unknown as IAppState));
    }

    public static runSagaOnStore(name: string, saga: Saga): void {
        if (!this.store.sagas!.includes(name)) {
            this.store.sagas!.push(name);
            this.store.sagaMiddleware!.run(saga);
        }
    }
}
