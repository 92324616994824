import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { Auth0ProviderWithHistory } from "@client/auth";
import createStore from "store";
import createTheme from "styles/client";
import { saga, reducer } from "utils/server-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { router } from "services/router";
import reportWebVitals from "./reportWebVitals";
import { TransparentLoader } from "./components/loader";

const store = createStore({ loadedData: { reducer } }, [saga]);
const theme = createTheme();

const container = document.getElementById("root");
const root = createRoot(container!);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { refetchOnWindowFocus: false, retry: false },
    },
});

root.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Auth0ProviderWithHistory>
                    <Suspense fallback={<TransparentLoader loading />}>
                        <RouterProvider router={router} />
                    </Suspense>
                </Auth0ProviderWithHistory>
            </Provider>
        </QueryClientProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
