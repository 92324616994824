import { Action, ReducersMapObject } from "redux";
import { IAppState } from "state";
import loading from "./loading";
import error from "./error";

export default (asyncReducers: ReducersMapObject) =>
    ({
        error,
        loading,
        ...asyncReducers,
    }) as ReducersMapObject<IAppState, Action>;
