import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const errorSlice = createSlice({
    name: "error",
    initialState: null as Error | null,
    reducers: {
        setError: (_, action: PayloadAction<Error>) => action.payload,
        resetError: () => null,
    },
});

export const { setError, resetError } = errorSlice.actions;
export default errorSlice.reducer;
