import React, { lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, Outlet } from "react-router-dom";
import App from "pages/client";

const XeroComplete = lazy(() => import("@client/pages/xero-complete"));

export const router = createBrowserRouter([
    { path: "/xero-complete", element: <XeroComplete /> },
    {
        element: (
            <HelmetProvider>
                <Outlet />
            </HelmetProvider>
        ),
        children: [{ path: "*", element: <App /> }],
    },
]);
